import styled from 'styled-components'
import {Link} from 'gatsby'

export const Nav = styled.nav`
    background: ${({active}) => active ? "#fff": "transparent"};
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;

    @media screen and (max-width: 
        960px) {
        background: ${({active}) => (active ? "#fff": "transparent")};
        transition: 0.8s all ease;
    }
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 1000px;

`

const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-right: 24px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavLogo = styled(Link)`
    color: #141414;
    justfy-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
`

export const Logo = styled.img`
    width: 11rem;
    margin: 0;
    padding: 0;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 960px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 960px) {
        display: flex;
        top: -80px;
        flex-direction: column;
        width: 100%;
        height: 70vh;
        position: absolute;
        top: ${({click}) => (click ? "100%": "-1000px")};
        opacity: 1;
        transition: all 0.2s ease;
        background: #fff;
    }
`

export const NavItem = styled.li`
    height: 80px;

    @media screen and (max-width: 960px) {
        width: 100%;
    }
`

export const NavLinks = styled(Link)`
    color: #141414;
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: capitalize;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;

    @media screen and (max-width: 960px) {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &: hover {
            color: #ff4040;
            transition: all 0.3s ease;
        }
    }
`
 
