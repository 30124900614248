import React from 'react'
import logo from '../../images/sitsLogo1.png'
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt'
import { BiEnvelope } from '@react-icons/all-files/bi/BiEnvelope'
import { FooterContainer, ContactIcons, ContactItems, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights, Logo} from './FooterElements'

const Footer = () => {
    return (
        <div>
            <FooterContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to="/">
                        <Logo src={logo} alt="Logo" />
                            </SocialLogo>
    <WebsiteRights>SITS LLC © {new Date().getFullYear()}</WebsiteRights>
    <ContactIcons>
        <ContactItems>
            <FaPhoneAlt /><span style={{margin: '10px'}}>763-777-5699</span>
        </ContactItems>
        <ContactItems>
            <BiEnvelope /><span style={{margin: '10px'}}>agsisto@yahoo.com</span>
        </ContactItems>
    
    </ContactIcons>
  
                        
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterContainer>
        </div>
    )
}

export default Footer
