import React from "react"
import Footer from "./Footer"
import Navbar from './Navbar/index'
import './layout.css'


const Layout = ({ children }) => {

  return (
    <div className="wrapper">
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}


export default Layout
