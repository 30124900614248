import styled from 'styled-components'
import {Link} from 'gatsby'
import Footer from '.'

export const FooterContainer = styled.div`
    background-color: #101522;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items; center;
    align-content: flex-end;
   
    width: 100%;
    height: 20%;
    bottom: 0;
    left: 0;
`

export const SocialMedia = styled.section`
    width: 100%;
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 10px auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`

export const SocialLogo = styled(Link)`
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`

export const Logo = styled.img`
    flex-grow: 1;
    width: 8rem;
    margin: 0;
    padding: 0;
`

export const WebsiteRights = styled.small`
    flex-grow: 1;
    color: #fff;
    margin-bottom: 16px;
    font-family: "Ubuntu", sans-serif;
    margin: 0 50px;
`

export const ContactIcons = styled.div`
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
    justify-content: space-between;
    align-items: flex-start;
    width: 350px;

    @media screen and (max-width: 760px) {
        text-align: center;
        flex-direction: row;
        width: 100%;
        display: table;

`

export const ContactItems = styled.div`   
    color: #fff;
    font-size: 1.2rem;
    margin: 0;
`

export default Footer